<template>
  <!--
    The view for the Resource Project CalendarView
  -->
  <Portlet
    :title="$t('menu.resourceProjectCalender')"
    icon="people-carry"
    class="rc-portlet"
  >
    <template slot="buttons">
      <toggle-button
        id="viewToggle"
        v-model="resourceView"
        :labels="{ checked: 'Resource', unchecked: 'Project' }"
        :color="{ checked: 'gray', unchecked: 'green' }"
        :width="90"
        :height="30"
        :font-size="10"
        class="m-0 mr-2"
        @change="changeView()"
      />
      <button 
        v-if="authenticationHasRole('projectmanager')" 
        v-tooltip="'Replace custom summary with Jira summary for all projects'"
        class="btn btn-sm btn-secondary mt-2 mb-2 mr-2"
        @click="syncProjects"
      >
        <span>
          Sync Projects
        </span>
      </button>
      <button 
        v-if="authenticationHasRole('projectmanager')" 
        class="btn btn-sm btn-secondary mt-2 mb-2 mr-2"
        @click="openProjectsModal"
      >
        <span>
          Projects
        </span>
      </button>
      <button 
        v-if="authenticationHasRole('projectmanager')" 
        class="btn btn-sm btn-secondary mt-2 mb-2 mr-2"
        @click="openResourcesModal"
      >
        <span>
          Resources
        </span>
      </button>
      <button 
        v-if="authenticationHasRole('projectmanager')" 
        class="btn btn-sm btn-primary float-right mt-2 mb-2"
        @click="openAddModal"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
    </template>
    <ResourceProjectCalendar
      ref="resourceProjectCalendar"
      :resource-view="resourceView"
    />
  </Portlet>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
export default {
  name: "ResourceProjectCalendarView",
  components: {
    ResourceProjectCalendar: () => import('@/components/OnCallDuty/ResourceProjectCalendar.vue'),
  },
  mixins: [
    authenticationMixin
  ],
  metaInfo () {
    return {
      title: this.$t('menu.resourceProjectCalender')
    }
  },
  data () {
    return {
      resourceView: true
    }
  },
  created () {
    if(!this.authenticationHasRole('scope_staff')) {
      this.$router.push('/');
    }
  },
  methods: {
    openAddModal () {
      this.$refs.resourceProjectCalendar.openAddModal();
    },
    openProjectsModal () {
      this.$refs.resourceProjectCalendar.openProjectsModal();
    },
    openResourcesModal () {
      this.$refs.resourceProjectCalendar.openResourcesModal();
    },
    syncProjects () {
      this.$refs.resourceProjectCalendar.syncProjects();
    },
    changeView () {
      this.resourceView = !!this.resourceView;
    }
  }
}
</script>

<style>
.rc-portlet {
  margin-bottom: -60px;
}
</style>